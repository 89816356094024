<template>
  <tr>
    <td> {{ shipment_id }}</td>
    <td>{{ recipient_fullname }}</td>
    <td>{{ conteudo }}</td>
    <td>{{ TAG }}</td>
    <td>
      <strong>
        <span :class="getStatusClass(status)">{{ formatStatus(status) }}</span>
      </strong>
    </td>
    <td>{{ JSON.parse(response_json)?.codigoObjeto }}</td>
    <td>{{ formatDate(created_at) }}</td>
    <td>
      <button @click="startEditing" class="btn btn-link p-0" title="Editar" :disabled="status != 'novo'">
        <i class="fas fa-pencil-alt"></i> 
      </button>&nbsp;&nbsp;
      <button  @click="deleteLine" class="btn btn-link p-0" title="Deletar" :disabled="(status != 'novo')">
        <i class="fas fa-trash"></i> 
      </button>&nbsp;&nbsp;
      <button @click="sendShipments" class="btn btn-link p-0" title="Enviar Prepostagem" :disabled="(status != 'novo')&&(status != 'aguardando_etiqueta')">
        <i class="fas fa-gears"></i> 
      </button>&nbsp;&nbsp;
      <button  @click="generateLabels" class="btn btn-link p-0" title="Gerar Etiqueta" :disabled="!((status == 'aguardando_etiqueta')||(status == 'etiqueta_gerada'))">
        <i class="fas fa-print"></i> 
      </button>
    </td>
  </tr>
</template>

<script>
import moment from 'moment';
import showSwal from "../../mixins/showSwal";
import shipmentsService from '../../services/shipments.service';

export default {
  props: ['shipment'],
  data() {
    return {
      shipment_id: this.shipment.id,
      sender_fullname: this.shipment.sender_fullname,
      recipient_fullname: this.shipment.recipient_fullname, 
      conteudo: this.shipment.conteudo,
      quantidade: this.shipment.quantidade,
      dataPrevistaPostagem: this.shipment.dataPrevistaPostagem,
      modalidadePagamento: this.shipment.modalidadePagamento,
      status: this.shipment.status,
      created_at: this.shipment.created_at,
      updated_at: this.shipment.updated_at,
      TAG: this.shipment.tag,
      response_json: this.shipment.response_json
    };
  },
  methods: {
    startEditing() {
      this.$store.dispatch('shipments/startEditing', this.shipment);
    },
    deleteLine() {
      this.$store.dispatch('shipments/deleteLine', this.shipment);
    },
    formatDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    formatStatus(status) {
      const statusMap = {
        novo: 'Novo',
        aguardando_etiqueta: 'Aguardando Etiqueta',
        etiqueta_gerada: 'Etiqueta Gerada',
        postado: 'Postado',
        em_transito: 'Em Trânsito',
        saiu_entrega: 'Saiu Para Entrega',
        entregue: 'Entregue',
        cancelado: 'Cancelado',
      };
      return statusMap[status] || 'Desconhecido';
    },
    getStatusClass(status) {
      const statusClasses = {
        novo: 'text-blue',
        aguardando_etiqueta: 'text-orange',
        etiqueta_gerada: 'text-orange',
        postado: 'text-yellow',
        em_transito: 'text-yellow',
        saiu_entrega: 'text-green',
        entregue: 'text-black',
        cancelado: 'text-red',
      };
      return statusClasses[status] || 'text-muted';
    },
    formatDateForApi(date) {
      // Verifica se a data é válida
      if (!date || isNaN(Date.parse(date))) {
        console.error("Data inválida fornecida:", date);
        return null; 
      }

      const d = new Date(date); // Converte a entrada em um objeto Date
      const day = String(d.getDate()).padStart(2, '0'); // Obtém o dia e garante que tenha dois dígitos
      const month = String(d.getMonth() + 1).padStart(2, '0'); // Obtém o mês (0-11) e ajusta para 1-12
      const year = d.getFullYear(); // Obtém o ano

      return `${day}/${month}/${year}`; // Retorna a data no formato dd/mm/yyyy
    },
    async sendShipments() {
      try {
        // Use Promise.all para obter detalhes do remetente, destinatário e envio

        

            // Formata os dados da pré-postagem
            const preShipmentData = {
              shipment_id: this.shipment.id
            };

            // Envie a requisição para a API
            const response = await shipmentsService.sendPrePostagem(preShipmentData);
            //const response = await this.$store.dispatch('shipments/sendPrePostagem', preShipmentData);
          console.log(response);
          
          showSwal.methods.showSwal({
          type: "success",
          message: "Pré-postagem enviada com sucesso!",
          timer: 5000,
        });
      } catch (error) {
        console.error("Erro ao enviar a pré-postagemasdas:", error);
        showSwal.methods.showSwal({
          type: "error",
          message: "Erro ao enviar a pré-postagem:\n" + (error.response ? error.response.data.correiosError : "Erro desconhecido"),
          width: "64em",
          timer: 20000
        });
      }
    },
    generateLabelHTML(requestBody) {
      const labelTemplate = `
        <!DOCTYPE html>
        <html lang="pt-br">
        <head>
            <meta charset="UTF-8">
            <meta name="viewport" content="width=device-width, initial-scale=1.0">
            <title>Etiqueta</title>
            <style>
                body {
                    font-family: Arial, sans-serif;
                }
                .label {
                    width: 400px;
                    border: 1px solid black;
                    padding: 10px;
                    margin: 0 auto;
                }
                .header {
                    display: flex;
                    justify-content: space-between;
                }
                .header img {
                    width: 50px;
                    height: 50px;
                }
                .barcode {
                    text-align: center;
                    margin: 20px 0;
                }
                .barcode img {
                    width: 100%;
                    height: auto;
                }
                .details, .recipient, .sender {
                    margin: 10px 0;
                }
                h2 {
                    font-size: 14px;
                    margin-bottom: 5px;
                }
                p {
                    font-size: 12px;
                    margin: 2px 0;
                }
                .logo {
                    text-align: right;
                }
                .footer {
                    margin-top: 20px;
                    text-align: center;
                }
                .footer p {
                    margin: 5px 0;
                }
                .bold {
                    font-weight: bold;
                }
                .recipient, .sender {
                    border-top: 1px solid black;
                    padding-top: 10px;
                }
                .signature {
                    border-top: 1px solid black;
                    margin-top: 10px;
                    padding-top: 10px;
                }
            </style>
        </head>
        <body>
            <div class="label">
                <div class="recipient">
                    <h2>Destinatário</h2>
                    <p class="bold">${requestBody.destinatario.nome}</p>
                    <p>${requestBody.destinatario.endereco.logradouro}, ${requestBody.destinatario.endereco.numero}</p>
                    <p>${requestBody.destinatario.endereco.bairro} - ${requestBody.destinatario.endereco.cidade}/${requestBody.destinatario.endereco.uf}</p>
                    <p>${requestBody.destinatario.endereco.cep}</p>
                    <p>CPF/CNPJ: ${requestBody.destinatario.cpf_cnpj}</p>
                </div>

                <div class="sender">
                    <h2>Remetente</h2>
                    <p class="bold">${requestBody.remetente.nome}</p>
                    <p>${requestBody.remetente.endereco.logradouro}, ${requestBody.remetente.endereco.numero}</p>
                    <p>${requestBody.remetente.endereco.bairro} - ${requestBody.remetente.endereco.cidade}/${requestBody.remetente.endereco.uf}</p>
                    <p>${requestBody.remetente.endereco.cep}</p>
                </div>
                  <div class="signature">
                    <p>Recebedor: <span></span></p>
                    <p>Assinatura: <span></span></p>
                    <p>Documento: <span></span></p>
                </div>
            </div>
        </body>
        </html>

        `;
      return labelTemplate;
    },
    generateContentDeclarationHTML(requestBody) {
    const declarationTemplate = `
      <!DOCTYPE html>
      <html lang="pt-br">
      <head>
          <meta charset="UTF-8">
          <meta name="viewport" content="width=device-width, initial-scale=1.0">
          <title>Declaração de Conteúdo</title>
          <style>
              body {
                  font-family: Arial, sans-serif;
              }
              .declaration {
                  width: 400px;
                  border: 1px solid black;
                  padding: 10px;
                  margin: 0 auto;
              }
              h2 {
                  font-size: 14px;
                  margin-bottom: 5px;
              }
              p {
                  font-size: 12px;
                  margin: 2px 0;
              }
              .content-item {
                  margin-top: 10px;
              }
          </style>
      </head>
      <body>
          <div class="declaration">
              <h2>Declaração de Conteúdo</h2>
              <div class="content-item">
                  <p><strong>Conteúdo:</strong> ${requestBody.itensDeclaracaoConteudo.conteudo}</p>
                  <p><strong>Quantidade:</strong> ${requestBody.itensDeclaracaoConteudo.quantidade}</p>
                  <p><strong>Valor:</strong> ${requestBody.itensDeclaracaoConteudo.valor}</p>
              </div>
          </div>
      </body>
      </html>
    `;
    return declarationTemplate;
  },
    generateLabels() {
      try {
        const pdf = shipmentsService.getStickers(this.shipment.id);
        Promise.all([pdf])
          .then(([pdfData]) => {

          const downloadUrl = window.URL.createObjectURL(new Blob([pdfData]));
          this.invoicePDF = downloadUrl
          const link = document.createElement('a');
          link.href = this.invoicePDF;
          link.setAttribute('download', 'file.pdf');
          document.body.appendChild(link);
          link.click();
          link.remove();
        });
        
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style scoped>
.text-blue {
  color: blue;
}

.text-orange {
  color: orange;
}

.text-green {
  color: rgb(21, 202, 21);
}

.text-black {
  color: black;
}

.text-red {
  color: red;
}
</style>
