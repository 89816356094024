<template>
    <div class="modal fade" id="recipient_modal" tabindex="-1">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="modal_demo_label">{{ title }}</h5>
          </div>
          <div class="modal-body">
            <form @submit.prevent="handleSubmit">
              <label for="fullname">Nome Completo</label>
              <soft-model-input
                id="fullname"
                v-model="state.fullname"
                type="text"
                placeholder="Nome Completo"
                name="fullname"
              />
  
              <label for="street">Rua</label>
              <soft-model-input
                id="street"
                v-model="state.street"
                type="text"
                placeholder="Rua"
                name="street"
              />
  
              <label for="neighborhood">Bairro</label>
              <soft-model-input
                id="neighborhood"
                v-model="state.neighborhood"
                type="text"
                placeholder="Bairro"
                name="neighborhood"
              />
  
              <label for="number">Número</label>
              <soft-model-input
                id="number"
                v-model="state.number"
                type="text"
                placeholder="Número"
                name="number"
              />
  
              <label for="complement">Complemento</label>
              <soft-model-input
                id="complement"
                v-model="state.complement"
                type="text"
                placeholder="Complemento (Opcional)"
                name="complement"
              />
  
              <label for="city">Cidade</label>
              <soft-model-input
                id="city"
                v-model="state.city"
                type="text"
                placeholder="Cidade"
                name="city"
              />
  
              <label for="uf">UF</label>
              <soft-model-input
                id="uf"
                v-model="state.uf"
                type="text"
                placeholder="UF"
                name="uf"
              />
  
              <label for="zipcode">CEP</label>
              <soft-model-input
                id="zipcode"
                v-model="state.zipcode"
                type="text"
                placeholder="CEP"
                name="zipcode"
              />
            </form>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="stopEditing">Cancelar</button>
            <button
              type="button"
              @click="handleSubmit"
              class="btn btn-success"
              :disabled="loading ? true : false"
            >
              <span
                v-if="loading"
                class="spinner-border spinner-border-sm"
              ></span>
              <span v-else>Salvar</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import bootstrap from "bootstrap/dist/js/bootstrap";
  import SoftModelInput from "@/components/SoftModelInput.vue";
  import formModalMixin from "../../mixins/form-modal-mixin";
  import * as yup from "yup";
  import { mapState } from "vuex";  
  import showSwal from "../../mixins/showSwal";
  
  const getState = (recipient = null) => ({
    id: recipient?.id,
    fullname: recipient?.fullname ?? '',
    street: recipient?.street ?? '',
    neighborhood: recipient?.neighborhood ?? '',
    number: recipient?.number ?? '',
    complement: recipient?.complement ?? '',
    city: recipient?.city ?? '',
    uf: recipient?.uf ?? '',
    zipcode: recipient?.zipcode ?? ''
  });
  
  export default {
    name: "RecipientModal",
    mixins: [formModalMixin('recipients')],
    components: {
      SoftModelInput,
    },
    mounted() {
      this.recipient_modal = new bootstrap.Modal('#recipient_modal', {
        backdrop: 'static'
      });
    },
    data() {
      const schema = yup.object().shape({
        fullname: yup.string().required(),
        street: yup.string().required(),
        neighborhood: yup.string().required(),
        number: yup.string().required(),
        complement: yup.string(),
        city: yup.string().required(),
        uf: yup.string().required().max(2),
        zipcode: yup.string().required(),
      });
  
      return {
        schema,
        loading: false,
        state: getState()
      };
    },
    computed: {
      ...mapState('recipients', {
        open: state => state.form.open,
        recipient: state => state.form.recipient,
        title: state => state.form.recipient?.fullname ?? "Novo Destinatário",
      }),
    },
    watch: {
      open(newOpen) {
        if (newOpen) this.openRecipientModal();
        else this.closeRecipientModal();
      },
      recipient(newRecipient) {
        this.state = getState(newRecipient);
      }
    },
    methods: {
      async handleSubmit() {
        this.loading = true;
        try {
          await this.schema.validate(this.state, { abortEarly: false });

          if (this.recipient) {
            await this.$store.dispatch('recipients/updateRecipient', this.state);
            showSwal.methods.showSwal({
              type: "success",
              message: "Destinatário atualizado com sucesso!",
              timer: 5000
            });
          } else {
            await this.$store.dispatch('recipients/addRecipient', this.state);
              showSwal.methods.showSwal({
                type: "success",
                message: "Destinatário salvo com sucesso!",
                timer: 5000
              });
            }
            this.state = getState();

          } catch (error) {
              if (error.name === 'ValidationError') {
                const errorMessage = error.errors.join(', ');
                showSwal.methods.showSwal({
                  type: "error",
                  message: `Erro de validação: ${errorMessage}`,
                  timer: 5000
                });
            } else {
                showSwal.methods.showSwal({
                  type: "error",
                  message: "Erro ao salvar o destinatário!",
                  timer: 5000
                });
              }
      } finally {
        this.loading = false;
      }
    },
      stopEditing() {
        this.$store.dispatch('recipients/stopEditing');
      },
      openRecipientModal() {
        this.recipient_modal.show();
      },
      closeRecipientModal() {
        this.recipient_modal.hide();
      }
    }
  };
  </script>
  