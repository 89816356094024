<template>
  <div class="w-auto h-auto collapse navbar-collapse max-height-vh-100 h-100" id="sidenav-collapse-main">
    <ul class="navbar-nav">
      <li v-for="({ name, icon }, index) in menuItems" :key="index">
        <sidenav-collapse :navText="name" :to="{ name: name }" :icon="icon" />
      </li>
    </ul>
  </div>
</template>
<script>
import SidenavCollapse from "./SidenavCollapse.vue";
import { routes } from "../../router/";

export default {
  name: "SidenavList",
  props: {
    cardBg: String,
  },
  data() {
    return {
      title: "Agencia 828",
      controls: "dashboardsExamples",
      isActive: "active",
    };
  },
  computed: {
    menuItems() {
      const isAdmin = this.$store.getters[ 'auth/isAdmin' ];

      return routes.filter(
        route => route.meta?.onMenu && (!route.meta?.isAdmin || isAdmin)
      ).map(route => ({
        name: route.name,
        icon: route.meta.icon
      }));
    }
  },
  components: {
    SidenavCollapse,
  },
  methods: {
    getRoute() {
      const routeArr = this.$route.path.split("/");
      return routeArr[1];
    },
  },
};
</script>
