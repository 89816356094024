<template>
    <tr @click="startEditing">
        <td>{{ name }}</td>
        <td>{{ email }}</td>
        <td>{{ is_admin }}</td>
        <td>{{ read_only }}</td>
        <td>{{ active }}</td>
        <td>{{ created_at }}</td>
    </tr>
</template>

<script>
import moment from 'moment';

export default {
    props: ['user'],
    data: (instance) => ({
        name: instance.user.name,
        email: instance.user.email,
        active: instance.user.active ? "Sim" : "Não",
        read_only: instance.user.read_only ? "Sim" : "Não",
        is_admin: instance.user.is_admin ? "Sim" : "Não",
        created_at: moment(instance.user.created_at).format("DD/MM/YYYY HH:mm")
    }),
    methods: {
        startEditing() {
            this.$store.dispatch('users/startEditing', this.user)
        }
    }
}
</script>