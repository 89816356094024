import shipmentsService from "../services/shipments.service";
import { parseFormError } from "../services/utils";

const initialState = {
  shipments: [],
  loading: false,
  page: 1,
  lastPage: 1,
  perPage: 1,
  total: 0,
  form: {
    open: false,
    shipment: null,
    error: null,
  },
  statusFilter: '',
  dateFilter: '',
  tagFilter: ''
};

export const shipments = {
  namespaced: true,
  state: initialState,
  actions: {
    async getShipments({ commit, state }) {
      commit("SET_LOADING", true);
      const response = await shipmentsService.getShipments(state.page, state.statusFilter, state.dateFilter, state.tagFilter);
      commit("SET_SHIPMENTS", response.data);
      commit("SET_PAGE", response.current_page);
      commit("SET_TOTAL", response.total);
      commit("SET_PER_PAGE", response.per_page);
      commit("SET_LAST_PAGE", response.last_page);
    },
    changeToPage({ commit, dispatch, state }, page) {
      if (page >= 1 && page <= state.lastPage) {
        commit("SET_PAGE", page);
        dispatch("getShipments");
      }
    },
    changeStatusFilter({ dispatch, commit },newStatus){
      commit("SET_STATUS", newStatus);
      dispatch("getShipments");
    },
    changeDateFilter({ dispatch, commit },newDate){
      commit("SET_DATE", newDate);
      dispatch("getShipments");
    },
    changeTagFilter({ dispatch, commit },newTag){
      commit("SET_TAG", newTag);
      dispatch("getShipments");
    },
    startAdding({ commit }) {
      const value = { open: true, shipment: null };
      commit("SET_FORM", value);
    },
    async addShipment({ dispatch, commit }, shipment) {
      try {
        await shipmentsService.addShipment(shipment);
        dispatch("stopEditing");
        dispatch("getShipments");
      } catch (error) {
        commit('SET_FORM_ERROR', parseFormError(error));
      }
    },
    async startEditing({ commit }, shipment) {
      const value = { open: true, shipment: shipment };
      commit("SET_FORM", value);
    },
    async updateShipment({ dispatch, commit }, shipment) {
      try {
        await shipmentsService.updateShipment(shipment);
        dispatch("stopEditing");
        dispatch("getShipments");
      } catch (error) {
        commit('SET_FORM_ERROR', parseFormError(error));
      }
    },
    async deleteLine({ dispatch, commit }, shipment) {
      try {
        await shipmentsService.deleteShipment(shipment);
        dispatch("getShipments");
      } catch (error) {
        commit('SET_FORM_ERROR', parseFormError(error));
      }
    },
    async stopEditing({ commit }) {
      const value = { open: false, shipment: null };
      commit("SET_FORM", value);
    },
    async sendPrePostagem({ commit }, shipment) {
      // try {
        //console.log('Dados enviados para a API: ', shipment)
        const response = await shipmentsService.sendPrePostagem(shipment);
        commit("SET_FORM", { open: false, shipment: null });
        //console.log('Resposta da api: ', response)
        return response; // Retorna a resposta da API

      // } catch (error) {
      //   console.error("Erro ao enviar pré-postagem:", error);
      // }
    },
  },
  mutations: {
    SET_SHIPMENTS(state, shipments) {
      state.shipments = shipments;
      state.loading = false;
    },
    SET_PAGE(state, page) {
      state.page = page;
    },
    SET_STATUS(state, newStatus) {
      state.statusFilter = newStatus;
    },
    SET_DATE(state, newDate) {
      state.dateFilter = newDate;
    },
    SET_TAG(state, newTag) {
      state.tagFilter = newTag;
    },
    SET_TOTAL(state, total) {
      state.total = total;
    },
    SET_PER_PAGE(state, per_page) {
      state.perPage = per_page;
    },
    SET_LAST_PAGE(state, last_page) {
      state.lastPage = last_page;
    },
    SET_LOADING(state, value) {
      state.loading = value;
    },
    SET_FORM(state, { open, shipment }) {
      state.form.open = open;
      state.form.shipment = shipment;
    },
    SET_FORM_ERROR(state, error) {
      state.form.error = error;
    },
    resetState(state) {
      state.shipments = [];
      state.loading = false;
      state.form = {
        open: false,
        shipment: null,
        error: null,
      };
    }
  },
};
