import sendersService from "../services/senders.service";
import { parseFormError } from "../services/utils";

const initialState = {
  senders: [],
  loading: false,
  page: 1,
  lastPage: 1,
  perPage: 10,
  total: 0,
  form: {
    open: false,
    sender: null,
    error: null,
  }
};

export const senders = {
  namespaced: true,
  state: initialState,
  actions: {
    async getSenders({ commit, state }) {
      commit("SET_LOADING", true);
      const response = await sendersService.getSenders(state.page);
      commit("SET_SENDERS", response.data);
      commit("SET_PAGE", response.current_page);
      commit("SET_TOTAL", response.total);
      commit("SET_PER_PAGE", response.per_page);
      commit("SET_LAST_PAGE", response.last_page);
    },
    async getAllSenders({ commit }) {
      commit("SET_LOADING", true);
      const response = await sendersService.getAllSenders();
      commit("SET_SENDERS", response);
      commit("SET_LOADING", false);
    },
    changeToPage({ commit, dispatch, state }, page) {
      if (page >= 1 && page <= state.lastPage) {
        commit("SET_PAGE", page);
        dispatch("getSenders");
      }
    },
    startAdding({ commit }) {
      const value = { open: true, sender: null };
      commit("SET_FORM", value);
    },
    async addSender({ dispatch, commit }, sender) {
      try {
        await sendersService.addSender(sender);
        dispatch("stopEditing");
        dispatch("getSenders");
      } catch (error) {
        commit('SET_FORM_ERROR', parseFormError(error));
      }
    },
    async startEditing({ commit }, sender) {
      const value = { open: true, sender: sender };
      commit("SET_FORM", value);
    },
    async updateSender({ dispatch, commit }, sender) {
      try {
        await sendersService.updateSender(sender);
        dispatch("stopEditing");
        dispatch("getSenders");
      } catch (error) {
        commit('SET_FORM_ERROR', parseFormError(error));
      }
    },
    async stopEditing({ commit }) {
      const value = { open: false, sender: null };
      commit("SET_FORM", value);
    },
    async importSenders({ commit }, fileData) {
      commit("SET_LOADING", true);
      try {
        const response = await sendersService.importSenders(fileData);
        commit("SET_SENDERS", response.data); 
        commit("SET_LOADING", false);
      } catch (error) {
        commit("SET_LOADING", false);
        commit('SET_FORM_ERROR', parseFormError(error)); 
      }
    },
  },
  mutations: {
    SET_SENDERS(state, senders) {
      state.senders = senders;
      state.loading = false;
    },
    SET_PAGE(state, page) {
      state.page = page;
    },
    SET_TOTAL(state, total) {
      state.total = total;
    },
    SET_PER_PAGE(state, per_page) {
      state.perPage = per_page;
    },
    SET_LAST_PAGE(state, last_page) {
      state.lastPage = last_page;
    },
    SET_LOADING(state, value) {
      state.loading = value;
    },
    SET_FORM(state, { open, sender }) {
      state.form.open = open;
      state.form.sender = sender;
    },
    SET_FORM_ERROR(state, error) {
      state.form.error = error;
    },
    resetState(state) {
      state.senders = [];
      state.loading = false;
      state.form = {
        open: false,
        sender: null,
        error: null,
      };
    }
  },
};
