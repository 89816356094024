import api from "./axios";

export default {
    // Método para obter remetentes paginados
    async getSenders(page = 1) {
        const response = await api.get(`/senders?page=${page}`);
        return response.data;
    },
    
    // Método para obter todos os remetentes
    async getAllSenders() {
        const response = await api.get(`/senders?all=true`);
        return response.data;
    },

    // Método para adicionar um novo remetente
    async addSender(sender) {
        const response = await api.post('/senders', sender);
        return response.data;
    },
    
    // Método para atualizar um remetente existente
    async updateSender(sender) {
        const response = await api.put(`/senders/${sender.id}`, sender);
        return response.data;
    },

    // Método para importar remetentes a partir de um arquivo
    async importSenders(fileData) {
        const response = await api.post('/import/senders', fileData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response.data;
    },
}
