<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="pb-0 card-header">
            <div class="d-lg-flex">
              <div>
                <h5 class="mb-0">Remetentes</h5>
              </div>
              <div class="my-auto mt-4 ms-auto mt-lg-0">
                <div class="my-auto ms-auto">
                  <a @click="startAdding" class="mb-0 btn bg-gradient-success btn-sm me-2">
                    +&nbsp; Novo Remetente
                  </a>
                  <a @click="openImportModal" class="mb-0 btn bg-gradient-success btn-sm me-2">
                    +&nbsp; Importar
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="spinner-border" role="status" v-if="loading">
            <span class="sr-only">Carregando...</span>
          </div>
          <div class="px-0 pb-0 card-body" v-else>
            <div class="table-responsive">
              <table id="senders-list" ref="sendersList" class="table table-flush">
                <thead class="thead-light">
                  <tr>
                    <th><strong>Nome Completo</strong></th>
                    <th><strong>Rua</strong></th>
                    <th><strong>Bairro</strong></th>
                    <th><strong>Número</strong></th>
                    <th><strong>Cidade</strong></th>
                    <th><strong>UF</strong></th>
                    <th><strong>CEP</strong></th>
                    <th><strong>Criado em</strong></th>
                  </tr>

                </thead>
                <tbody class="text-sm">
                  <sender-tile v-for="(sender, index) in senders" :sender="sender" :key="index" />
                </tbody>
              </table>
            </div>
          </div>
          <pagination 
            :current="page"
            :perPage="perPage"
            :onChangePage="changeToPage"
            :totalPages="lastPage"
            :totalValues="total"
          /> 
        </div>
      </div>
    </div>

    <div v-if="isImportModalOpen" class="modal fade show" style="display: block; background: rgba(0,0,0,0.5);" @click.self="closeImportModal">
      <div class="modal-dialog">
        <div class="modal-content">
        </div>
        <div class="modal-body">
          <sender-import @close="closeImportModal" @senders-imported="refreshSenders" /> <!-- Ouça o evento aqui -->
        </div>
      </div>
    </div>

    <sender-modal />
  </div>
</template>

<script>
import SenderTile from "./components/SenderTile.vue";
import SenderModal from "./components/SenderModal.vue";
import SenderImport from "./components/SenderImport.vue"; 
import { mapState } from 'vuex';
import Pagination from "../components/Pagination.vue";
import list_mixin from "../mixins/list_mixin";

export default {
  name: "senders",

  components: {
    SenderTile,
    SenderModal,
    SenderImport, 
    Pagination
  },

  mixins: [list_mixin('senders')],

  data() {
    return {
      isImportModalOpen: false,
    };
  },

  computed: {
    ...mapState('senders', {
      senders: state => state.senders,
    }),
  },

  methods: {
    openImportModal() {
      this.isImportModalOpen = true;
    },
    closeImportModal() {
      this.isImportModalOpen = false; 
    },
    async refreshSenders() { 
      try {
        await this.$store.dispatch("senders/getSenders");
      } catch (error) {
        console.error(error);
      }
    }
  },

  async created() {
    try {
      await this.$store.dispatch("senders/getSenders");
    } catch (error) {
      console.error(error);
    }
  },
};
</script>

<style>
td {
  padding: 12px 20px;
}
</style>
