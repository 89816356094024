<template>
  <div class="modal fade" id="shipment_modal" tabindex="-1">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modal_demo_label">{{ title }}</h5>
        </div>
        <div class="modal-body">
          <form @submit.prevent="handleSubmit">
            <!-- Campo de Seleção do Remetente -->
            <label for="sender_id">Remetente <span class="required">*</span></label>
            <select
              id="sender_id"
              v-model="state.sender_id"
              name="sender_id"
              class="form-select"
              required
            >
              <option value="">Selecione o Remetente</option>
              <option v-for="sender in senders" :key="sender.id" :value="sender.id">
                {{ sender.fullname }} 
              </option>
            </select>

            <!-- Dados Automáticos do Remetente -->
            <div v-if="state.sender_id" class="mt-3">
              <h6>Detalhes do Remetente:</h6>
              <ul class="list-unstyled">
                <li><strong>Nome:</strong> {{ state.sender_fullname }}</li>
                <li><strong>Endereço:</strong> {{ state.sender_street }},  {{ state.sender_number }}</li>
                <li><strong>Complemento:</strong> {{ state.sender_complement }}</li>
                <li><strong>Bairro:</strong> {{ state.sender_neighborhood }}</li>
                <li><strong>Cidade:</strong> {{ state.sender_city }} - {{ state.sender_uf }}</li>
                <li><strong>CEP:</strong> {{ state.sender_zipcode }}</li>
              </ul>
            </div>

            <!-- Campo de Seleção de Múltiplos Destinatários -->
            <label for="recipient_ids" class="mt-3">Destinatários <span class="required">*</span></label>
            <select
              id="recipient_ids"
              v-model="state.recipient_ids"
              name="recipient_ids"
              class="form-select"
              multiple
              required
            >
              <option value="">Selecione o Destinatário</option>
              <option v-for="recipient in recipients" :key="recipient.id" :value="recipient.id">
                {{ recipient.fullname }} 
              </option>
            </select>

            <!-- Botão para Importar Destinatários -->
            <!-- <button 
              type="button" 
              class="mb-0 btn bg-gradient-success btn-sm me-2 mt-3" 
              @click="openImportModal">
                Importar Destinatários
            </button> -->

            <div v-if="isImportModalOpen" class="modal fade show" style="display: block; background: rgba(0,0,0,0.5);" @click.self="closeImportModal">
              <div class="modal-dialog">
                <div class="modal-content">
                </div>
                <div class="modal-body">
                  <recipient-import @close="closeImportModal" @recipients-imported="refreshRecipients" /> <!-- Ouça o evento aqui -->
                </div>
              </div>
            </div>

            <!-- Exibição de quantidade de destinatários -->
            <div class="mt-3">
              <p><strong>{{ state.recipient_ids.length }}</strong> destinatário(s) selecionado(s).</p>
            </div>

            <!-- Conteúdo -->
            <label for="conteudo" class="mt-3">Conteúdo <span class="required">*</span></label>
            <soft-model-input
              id="conteudo"
              v-model="state.conteudo"
              type="text"
              placeholder="Conteúdo do Envio"
              name="conteudo"
              required
            />

            <!-- Quantidade -->
            <label for="quantidade" class="mt-3">Quantidade <span class="required">*</span></label>
            <soft-model-input
              id="quantidade"
              v-model="state.quantidade"
              type="number"
              placeholder="Quantidade"
              name="quantidade"
              required
            />

            <!-- Valor -->
            <label for="valor" class="mt-3">Valor <span class="required">*</span></label>
            <soft-model-input
              id="valor"
              v-model="state.valor"
              type="number"
              placeholder="Valor"
              name="valor"
              required
            />

            <!-- Peso Informado -->
            <label for="pesoInformado" class="mt-3">Peso Informado <span class="required">*</span></label>
            <soft-model-input
              id="pesoInformado"
              v-model="state.pesoInformado"
              type="number"
              placeholder="Peso (g)"
              name="pesoInformado"
              required
            />       

            <!-- Dimensões -->
            <label for="codigoFormatoObjetoInformado" class="mt-3">Formato do Objeto <span class="required">*</span></label>
            <select
              id="codigoFormatoObjetoInformado"
              v-model="state.codigoFormatoObjetoInformado"
              name="codigoFormatoObjetoInformado"
              class="form-select"
              required
            >
              <option value="" disabled>Selecione o formato do objeto</option>
              <option value="1">Envelope</option>
              <option value="2">Caixa / Pacote</option>
              <option value="3">Cilindro / Rolo</option>
            </select>

            <label for="alturaInformada" class="mt-3">Altura (cm) <span class="required">*</span></label>
            <soft-model-input
              id="alturaInformada"
              v-model="state.alturaInformada"
              type="number"
              placeholder="Altura"
              name="alturaInformada"
              required
            />

            <label for="larguraInformada" class="mt-3">Largura (cm) <span class="required">*</span></label>
            <soft-model-input
              id="larguraInformada"
              v-model="state.larguraInformada"
              type="number"
              placeholder="Largura"
              name="larguraInformada"
              required
            />

            <label for="comprimentoInformado" class="mt-3">Comprimento (cm) <span class="required">*</span></label>
            <soft-model-input
              id="comprimentoInformado"
              v-model="state.comprimentoInformado"
              type="number"
              placeholder="Comprimento"
              name="comprimentoInformado"
              required
            />

            <!-- Ciente Objeto Não Proibido -->
            <label for="cienteObjetoNaoProibido" class="mt-3">Ciente Objeto Não Proibido <span class="required">*</span></label>
            <select
              id="cienteObjetoNaoProibido"
              v-model="state.cienteObjetoNaoProibido"
              name="cienteObjetoNaoProibido"
              class="form-select"
              required
            >
              <option value="" disabled>Selecione</option>
              <option value="1">Sim</option>
              <option value="0">Não</option>
            </select>

            <!-- Solicitar Coleta -->
            <label for="solicitarColeta" class="mt-3">Solicitar Coleta <span class="required">*</span></label>
            <select
              id="solicitarColeta"
              v-model="state.solicitarColeta"
              name="solicitarColeta"
              class="form-select"
              required
            >
              <option value="" disabled>Selecione</option>
              <option value="S">Sim</option>
              <option value="N">Não</option>
            </select>

            <!-- Data Prevista para Postagem -->
            <label for="dataPrevistaPostagem" class="mt-3">Data Prevista para Postagem <span class="required">*</span></label>
            <soft-model-input
              id="dataPrevistaPostagem"
              v-model="state.dataPrevistaPostagem"
              type="date"
              name="dataPrevistaPostagem"
              required
            />

            <!-- Modalidade de Pagamento -->
            <!-- <label for="modalidadePagamento" class="mt-3">Modalidade de Pagamento <span class="required">*</span></label>
            <select
              id="modalidadePagamento"
              v-model="state.modalidadePagamento"
              name="modalidadePagamento"
              class="form-select"
              required
            >
              <option value="" disabled>Selecione a Modalidade de Pagamento</option>
              <option value="1">À vista</option>
              <option value="2">À faturar</option>
              <option value="3">À vista e à faturar</option>
              <option value="4">Prestação de contas recebimento ou pagamento</option>
            </select> -->

            <!-- Logística Reversa -->
            <label for="logisticaReversa" class="mt-3">Logística Reversa <span class="required">*</span></label>
            <select
              id="logisticaReversa"
              v-model="state.logisticaReversa"
              name="logisticaReversa"
              class="form-select"
              required
            >
              <option value="" disabled>Selecione</option>
              <option value="S">Sim</option>
              <option value="N">Não</option>
            </select>

            <!-- Código Serviço -->
            <label for="codigoServico" class="mt-3">Serviço<span class="required">*</span></label>
              <select
                id="codigoServico"
                v-model="state.codigoServico"
                name="codigoServico"
                class="form-select"
                required
              >
                <option value="" disabled>Selecione</option>
                <option value="03220">Sedex</option>
                <option value="03298">PAC</option>
              </select>

              <!-- Status -->
              <!-- <label for="status" class="mt-3">Status <span class="required">*</span></label>
              <select
              id="status"
              v-model="state.status"
              name="status"
              class="form-select"
              required
            >
              <option value="" disabled>Selecione o Status</option>
              <option value="novo">Novo</option>
              <option value="aguardando_etiqueta">Aguardando Etiqueta</option>
              <option value="etiqueta_gerada">Etiqueta Gerada</option>
              <option value="em_transito">Em Trânsito</option>
              <option value="saiu_entrega">Saiu Para Entrega</option>
              <option value="entregue">Entregue</option>
              <option value="cancelado">Cancelado</option>
            </select> -->

          </form>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="stopEditing">Cancelar</button>
          <button
            type="button"
            @click="handleSubmit"
            class="btn btn-success"
            :disabled="loading ? true : false"
          >
            <span
              v-if="loading"
              class="spinner-border spinner-border-sm"
            ></span>
            <span v-else>Salvar</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import bootstrap from "bootstrap/dist/js/bootstrap";
import SoftModelInput from "@/components/SoftModelInput.vue";
import formModalMixin from "../../mixins/form-modal-mixin";
import * as yup from "yup";
import { mapState, mapActions } from "vuex";  
import showSwal from "../../mixins/showSwal";
import RecipientImport from "./RecipientImport.vue";

const getState = (shipment = null) => ({
  id: shipment?.id,
  sender_id: shipment?.sender_id ?? '',
  recipient_ids: shipment?.recipient_ids ?? [],
  conteudo: shipment?.conteudo ?? '', 
  quantidade: shipment?.quantidade ?? 0, 
  valor: shipment?.valor ?? '', 
  pesoInformado: shipment?.pesoInformado ?? '', 
  codigoFormatoObjetoInformado: shipment?.codigoFormatoObjetoInformado ?? '', 
  alturaInformada: shipment?.alturaInformada ?? '', 
  larguraInformada: shipment?.larguraInformada ?? '', 
  comprimentoInformado: shipment?.comprimentoInformado ?? '',
  cienteObjetoNaoProibido: shipment?.cienteObjetoNaoProibido ?? '',
  solicitarColeta: shipment?.solicitarColeta ?? '',
  dataPrevistaPostagem: shipment?.dataPrevistaPostagem ?? '',
  //modalidadePagamento: shipment?.modalidadePagamento ?? '',
  logisticaReversa: shipment?.logisticaReversa ?? '',
  codigoServico: shipment?.codigoServico ?? ''
});

export default {
  name: "ShipmentModal",
  mixins: [formModalMixin('shipments')],
  components: {
    SoftModelInput,
    RecipientImport,
  },
  mounted() {
    this.shipment_modal = new bootstrap.Modal('#shipment_modal', {
      backdrop: 'static'
    });

    this.getSenders();
    this.getRecipients();
  },
  data() {
    const schema = yup.object().shape({
      sender_id: yup.string().required('Remetente é obrigatório.'),
      recipient_ids: yup.array().min(1, 'Selecione ao menos um destinatário').required(),
      conteudo: yup.string().required('Conteúdo é obrigatório.'), 
      quantidade: yup.number().min(1, 'Quantidade deve ser maior que zero.').required('Quantidade é obrigatória.'), 
      valor: yup.number().optional(), 
      pesoInformado: yup.number().required('Peso é obrigatório.').positive('Peso deve ser positivo.'), 
      codigoFormatoObjetoInformado: yup.string().required('Formato do objeto é obrigatório.'),
      alturaInformada: yup.number().required('Altura é obrigatória.').positive('Altura deve ser positiva.'), 
      larguraInformada: yup.number().required('Largura é obrigatória.').positive('Largura deve ser positiva.'), 
      comprimentoInformado: yup.number().required('Comprimento é obrigatório.').positive('Comprimento deve ser positivo.'), 
      cienteObjetoNaoProibido: yup.string().required('Confirmação de objeto não proibido é obrigatória.'), 
      solicitarColeta: yup.string().required('Confirmação de solicitação de coleta é obrigatória.'), 
      dataPrevistaPostagem: yup.date().required('Data prevista para postagem é obrigatória.'), 
      //modalidadePagamento: yup.string().required('Modalidade de pagamento é obrigatória.'), 
      logisticaReversa: yup.string().required('Confirmação de logística reversa é obrigatória.'), 
      codigoServico: yup.string().required('Serviço é obrigatório'),
      // status: yup.string().required('Status é obrigatório.').oneOf(['novo', 'aguardando_etiqueta', 'etiqueta_gerada', 'enviado', 'entregue', 'cancelado'], 'Status inválido.'),
    });

    return {
      schema,
      loading: false,
      state: getState(),
      isImportModalOpen: false,
    };
  },
  computed: {
    ...mapState({
      senders: state => state.senders.senders, 
      recipients: state => state.recipients.recipients,
      open: state => state.shipments.form.open,
      shipment: state => state.shipments.form.shipment,
      title: state => state.shipments.form.shipment?.id ? "Editar Envio" : "Novo Envio",
    }),
  },
  watch: {
    open(newOpen) {
      if (newOpen) this.openShipmentModal();
      else this.closeShipmentModal();
    },
    shipment(newShipment) {
      this.state = getState(newShipment);
      if (newShipment && newShipment.recipient_id) {
        this.state.recipient_ids = [newShipment.recipient_id];
      }
    },
    'state.sender_id'(newSenderId) {
      const sender = this.senders.find(s => s.id === newSenderId);
      if (sender) {
        this.state.sender_fullname = sender.fullname;
        this.state.sender_street = sender.street;
        this.state.sender_neighborhood = sender.neighborhood;
        this.state.sender_number = sender.number;
        this.state.sender_complement = sender.complement;
        this.state.sender_city = sender.city;
        this.state.sender_uf = sender.uf;
        this.state.sender_zipcode = sender.zipcode;
      }
    },
  },
  methods: {
    ...mapActions({
      getSenders: 'senders/getAllSenders',
      getRecipients: 'recipients/getAllRecipients',
      stopEditing: 'shipments/stopEditing',
    }),
    openImportModal() {
      this.isImportModalOpen = true;
    },
    closeImportModal() {
      this.isImportModalOpen = false; 
    },
    async openShipmentModal() {
      this.state = getState(); 
      this.shipment_modal.show();
    },
    async closeShipmentModal() {
      this.shipment_modal.hide(); 
    },
    async refreshRecipients() { 
      try {
        await this.$store.dispatch("recipients/getAllRecipients");

        this.closeImportModal();
      } catch (error) {
        console.error(error);
      }
    },
    async handleSubmit() {
      this.loading = true;
      try {
        if (this.state.id) {
          await this.$store.dispatch('shipments/updateShipment', this.state);
          showSwal.methods.showSwal({
            type: "success",
            message: "Envio atualizado com sucesso!",
            timer: 5000
          });
        } else {
          
          
          

          const shipmentData = { ...this.state };
          await this.$store.dispatch('shipments/addShipment', shipmentData);
          // Exibir mensagem com a quantidade de envios
          showSwal.methods.showSwal({
            type: "success",
            message: `Envio(s) salvo(s) com sucesso!`,
            timer: 5000
          });
        }
        this.loading = false;
        this.state = getState();
      } catch (error) {
        this.loading = false;
        showSwal.methods.showSwal({
          type: "error",
          message: "Erro ao salvar o envio.",
          timer: 5000
        });
      }
    },
  },
};
</script>

<style scoped>
.required {
  color: red;
}
</style>
