<template>
    <div class="py-4 container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <!-- Card header -->
            <div class="pb-0 card-header">
              <div class="d-lg-flex">
                <div>
                  <h5 class="mb-0">Envios</h5>
                </div>
                <div class="my-auto mt-4 ms-auto mt-lg-0">
                  <div class="my-auto ms-auto">
                    Filtro:
                    <select
                      id="status"
                      style="display: inline-block; width: inherit"
                      name="statusfilter"
                      class="form-select"
                      v-model="statusFilter"
                      @change="changeStatusFilter"
                    >
                      <option value="">Status</option>
                      <option value="novo">Novo</option>
                      <option value="aguardando_etiqueta">Aguardando Etiqueta</option>
                      <option value="etiqueta_gerada">Etiqueta Gerada</option>
                      <option value="postado">Postado</option>
                      <option value="em_transito">Em Trânsito</option>
                      <option value="saiu_entrega">Saiu Para Entrega</option>
                      <option value="entregue">Entregue</option>
                      <option value="cancelado">Cancelado</option>
                    </select>
                     Data Criação:
                    <soft-model-input
                      type="date"
                      id="dateFilter"
                      style="display: inline-block; width: inherit"
                      name="dateFilter"
                      class="form-input"
                      v-model="dateFilter"
                      @change="changeDateFilter"
                    />&nbsp;
                    <soft-model-input
                      type="text"
                      id="tagFilter"
                      style="display: inline-block; width: inherit"
                      name="tagFilter"
                      class="form-input"
                      placeholder="TAG"
                      v-model="tagFilter"
                      @input="changeTagFilter"
                    />
                  </div>
                </div>
                <div class="my-auto mt-4 ms-auto mt-lg-0">
                  <div class="my-auto ms-auto">
                    <a @click="startPreposting" class="mb-0 btn bg-gradient-success btn-md">
                      <i class="fas fa-gears"></i>&nbsp;Prepostar tudo
                    </a>&nbsp;
                    <a @click="generateLabels" class="mb-0 btn bg-gradient-success btn-md">
                      <i class="fas fa-print"></i>&nbsp;Gerar Etiquetas
                    </a>&nbsp;
                    <a @click="startAdding" title="Novo Envio" class="mb-0 btn bg-gradient-success btn-md">
                      +
                    </a>&nbsp;
                    <a @click="exportExcel" title="Exportar Excel" class="mb-0 btn bg-gradient-success btn-md">
                      <i class="fas fa-file-excel"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="spinner-border" role="status" v-if="loading">
              <span class="sr-only">Carregando...</span>
            </div>
            <div class="px-0 pb-0 card-body" v-else>
              <div class="table-responsive">
                <table id="shipments-list" ref="shipmentsList" class="table table-flush">
                  <thead class="thead-light">
                    <tr>
                      <th><strong>ID</strong></th>
                      <th><strong>Destinatário</strong></th>
                      <th><strong>Conteúdo</strong></th>
                      <th><strong>TAG</strong></th>
                      <th><strong>Status</strong></th>
                      <th><strong>Rastreio</strong></th>
                      <th><strong>Criado em</strong></th>
                      <th><strong>Ações</strong></th>
                    </tr>

                  </thead>
                  <tbody class="text-sm">
                    <shipment-tile v-for="(shipment, index) in shipments" :shipment="shipment" :key="index" />
                  </tbody>
                </table>
              </div>
            </div>
            <pagination 
              :current="page"
              :perPage="perPage"
              :onChangePage="changeToPage"
              :totalPages="lastPage"
              :totalValues="total"
            /> 
          </div>
        </div>
      </div>
    </div>
  
    <shipment-modal />
    
  </template>
  
  <script>
  import ShipmentTile from "./components/ShipmentTile.vue";
  import ShipmentModal from "./components/ShipmentModal.vue";
  import { mapState } from 'vuex'
  import Pagination from "../components/Pagination.vue";
  import list_mixin from "../mixins/list_mixin";
  import shipmentsService from '../services/shipments.service';
  import showSwal from "../mixins/showSwal";
  import SoftModelInput from "@/components/SoftModelInput.vue";

  export default {
    name: "Shipments",
  
    components: {
      ShipmentTile,
      ShipmentModal,
      Pagination,
      SoftModelInput,
    },
  
    mixins: [list_mixin('shipments')],
  
    computed: {
      ...mapState('shipments', {
        shipments: state => state.shipments
      }),
    },

    data(){
      return {
        statusFilter: '',
        dateFilter: '',
        tagFilter: ''
      }
    },
  
    async created() {
      try {
        await this.$store.dispatch("shipments/getShipments", this.statusFilter);
      } catch (error) {
        console.error(error);
      }
    },

    methods:{
      async startPreposting(){
        try {

          const preShipmentData = {
            shipment_id: -1
          };

          // Envie a requisição para a API
          await shipmentsService.sendPrePostagem(preShipmentData);
          
          showSwal.methods.showSwal({
            type: "success",
            message: "Pré-postagem enviada com sucesso!",
            timer: 5000,
          });
        } catch (error) {
          console.error("Erro ao enviar a pré-postagemasdas:", error);
          showSwal.methods.showSwal({
            type: "error",
            message: "Erro ao enviar a pré-postagem:\n" + (error.response ? error.response.data.correiosError : "Erro desconhecido"),
            width: "64em",
            timer: 20000
          });
        }
      },
      generateLabels(){
        try {
          const pdf = shipmentsService.getStickers(-1);
          Promise.all([pdf])
            .then(([pdfData]) => {

            const downloadUrl = window.URL.createObjectURL(new Blob([pdfData]));
            this.invoicePDF = downloadUrl
            const link = document.createElement('a');
            link.href = this.invoicePDF;
            link.setAttribute('download', 'file.pdf');
            document.body.appendChild(link);
            link.click();
            link.remove();
          });
          
        } catch (error) {
          console.error(error);
        }
      },
      exportExcel(){
        try {
          const excel = shipmentsService.exportExcel(this.statusFilter,this.dateFilter,this.tagFilter);
          Promise.all([excel])
            .then(([excelData]) => {

            const downloadUrl = window.URL.createObjectURL(new Blob([excelData]));
            this.invoicePDF = downloadUrl
            const link = document.createElement('a');
            link.href = this.invoicePDF;
            link.setAttribute('download', 'file.xlsx');
            document.body.appendChild(link);
            link.click();
            link.remove();
          });
          
        } catch (error) {
          console.error(error);
        }
      },
      async changeStatusFilter(event){
        try {
          await this.$store.dispatch("shipments/changeStatusFilter", event.target.value);
        } catch (error) {
          console.error(error);
        }
      },
      async changeDateFilter(event){
        try {
          await this.$store.dispatch("shipments/changeDateFilter", event.target.value);
        } catch (error) {
          console.error(error);
        }
      },
      async changeTagFilter(event){
        try {
          await this.$store.dispatch("shipments/changeTagFilter", event.target.value);
        } catch (error) {
          console.error(error);
        }
      }
    }
  };
  </script>
  
  <style>
  td {
    padding: 12px 24px !important;
  }
  </style>
  