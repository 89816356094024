<template>
  <div class="modal fade" id="sender_modal" tabindex="-1">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modal_demo_label">{{ title }}</h5>
        </div>
        <div class="modal-body">
          <form @submit.prevent="handleSubmit">
            <label for="fullname">Nome Completo</label>
            <soft-model-input
              id="fullname"
              v-model="state.fullname"
              type="text"
              placeholder="Nome Completo"
              name="fullname"
            />

            <label for="street">Rua</label>
            <soft-model-input
              id="street"
              v-model="state.street"
              type="text"
              placeholder="Rua"
              name="street"
            />

            <label for="neighborhood">Bairro</label>
            <soft-model-input
              id="neighborhood"
              v-model="state.neighborhood"
              type="text"
              placeholder="Bairro"
              name="neighborhood"
            />

            <label for="number">Número</label>
            <soft-model-input
              id="number"
              v-model="state.number"
              type="text"
              placeholder="Número"
              name="number"
            />

            <label for="complement">Complemento</label>
            <soft-model-input
              id="complement"
              v-model="state.complement"
              type="text"
              placeholder="Complemento (Opcional)"
              name="complement"
            />

            <label for="city">Cidade</label>
            <soft-model-input
              id="city"
              v-model="state.city"
              type="text"
              placeholder="Cidade"
              name="city"
            />

            <label for="uf">UF</label>
            <soft-model-input
              id="uf"
              v-model="state.uf"
              type="text"
              placeholder="UF"
              name="uf"
            />

            <label for="zipcode">CEP</label>
            <soft-model-input
              id="zipcode"
              v-model="state.zipcode"
              type="text"
              placeholder="CEP"
              name="zipcode"
            />

            <label for="ddd">DDD</label>
            <soft-model-input
              id="ddd"
              v-model="state.ddd"
              type="text"
              placeholder="DDD"
              name="ddd"
            />

            <label for="phone">Telefone</label>
            <soft-model-input
              id="phone"
              v-model="state.phone"
              type="text"
              placeholder="Telefone"
              name="phone"
            />

            <label for="email">E-mail</label>
            <soft-model-input
              id="email"
              v-model="state.email"
              type="email"
              placeholder="Email"
              name="email"
            />

            <label for="cpf_cnpj">CPF/CNPJ</label>
            <soft-model-input
              id="cpf_cnpj"
              v-model="state.cpf_cnpj"
              type="text"
              placeholder="CPF ou CNPJ"
              name="cpf_cnpj"
            />
          </form>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="stopEditing">Cancelar</button>
          <button
            type="button"
            @click="handleSubmit"
            class="btn btn-success"
            :disabled="loading ? true : false"
          >
            <span v-if="loading" class="spinner-border spinner-border-sm"></span>
            <span v-else>Salvar</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import bootstrap from "bootstrap/dist/js/bootstrap";
import SoftModelInput from "@/components/SoftModelInput.vue";
import formModalMixin from "../../mixins/form-modal-mixin";
import * as yup from "yup";
import { mapState } from "vuex";  
import showSwal from "../../mixins/showSwal";

const getState = (sender = null) => ({
  id: sender?.id,
  fullname: sender?.fullname ?? '',
  street: sender?.street ?? '',
  neighborhood: sender?.neighborhood ?? '',
  number: sender?.number ?? '',
  complement: sender?.complement ?? '',
  city: sender?.city ?? '',
  uf: sender?.uf ?? '',
  zipcode: sender?.zipcode ?? '',
  ddd: sender?.ddd ?? '',
  phone: sender?.phone ?? '',
  email: sender?.email ?? '',
  cpf_cnpj: sender?.cpf_cnpj ?? ''
});

export default {
  name: "SenderModal",
  mixins: [formModalMixin('senders')],
  components: {
    SoftModelInput,
  },
  mounted() {
    this.sender_modal = new bootstrap.Modal('#sender_modal', {
      backdrop: 'static'
    });
  },
  data() {
    const schema = yup.object().shape({
      fullname: yup.string().required(),
      street: yup.string().required(),
      neighborhood: yup.string().required(),
      number: yup.string().required(),
      complement: yup.string(),
      city: yup.string().required(),
      uf: yup.string().required().max(2),
      zipcode: yup.string().required(),
      ddd: yup.string().nullable().max(3),
      phone: yup.string().nullable().max(9),
      email: yup.string().nullable().email(),
      cpf_cnpj: yup.string().nullable().max(14),
    });

    return {
      schema,
      loading: false,
      state: getState()
    };
  },
  computed: {
    ...mapState('senders', {
      open: state => state.form.open,
      sender: state => state.form.sender,
      title: state => state.form.sender?.fullname ?? "Novo Remetente",
    }),
  },
  watch: {
    open(newOpen) {
      if (newOpen) this.openSenderModal();
      else this.closeSenderModal();
    },
    sender(newSender) {
      this.state = getState(newSender);
    }
  },
  methods: {
    async handleSubmit() {
      this.loading = true;
      try {
        await this.schema.validate(this.state, { abortEarly: false });

        if (this.sender) {
          await this.$store.dispatch('senders/updateSender', this.state);
          showSwal.methods.showSwal({
            type: "success",
            message: "Remetente atualizado com sucesso!",
            timer: 5000
          });
        } else {
          await this.$store.dispatch('senders/addSender', this.state);
          showSwal.methods.showSwal({
            type: "success",
            message: "Remetente salvo com sucesso!",
            timer: 5000
          });
        }
        this.state = getState();

      } catch (error) {
          if (error.name === 'ValidationError') {
            const errorMessage = error.errors.join(', ');
            showSwal.methods.showSwal({
              type: "error",
              message: `Erro de validação: ${errorMessage}`,
              timer: 5000
            });
          } else {
              showSwal.methods.showSwal({
                type: "error",
                message: "Erro ao salvar o remetente!",
                timer: 5000
              });
            }
      } finally {
        this.loading = false;
      }
    },
    stopEditing() {
      this.$store.dispatch('senders/stopEditing');
    },
    openSenderModal() {
      this.sender_modal.show();
    },
    closeSenderModal() {
      this.sender_modal.hide();
    }
  }
};
</script>
