<template>
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Importar Remetentes</h5>
      </div>
      <div class="modal-body">
        <p class="instruction-text">Importe arquivos em .xls</p> <!--Validar .csv, .xlsx, e .json-->
        <input
          type="file"
          @change="handleFileUpload"
          accept=".csv, .xlsx, .xls, .json"
          class="file-input"
        />
        <div class="button-group"> 
          <button @click="closeModal" class="btn btn-secondary">Cancelar</button>
          <button @click="importSenders" class="btn bg-gradient-success" :disabled="loading">
            <span v-if="loading" class="spinner-border spinner-border-sm"></span>
            <span v-else>Importar</span>
          </button>
        </div>
        <div v-if="error" class="text-danger error-message">{{ error }}</div>
      </div>
    </div>
  </template>
  
  <script>
  import { mapState } from 'vuex';
  
  export default {
    name: "SenderImport",
    data() {
      return {
        loading: false,
        file: null,
        error: null,
      };
    },
    computed: {
      ...mapState('senders', {
        senders: state => state.senders,
      }),
    },
    methods: {
      handleFileUpload(event) {
        this.file = event.target.files[0];
        this.error = null; 
      },
      async importSenders() {
        if (!this.file) {
          this.error = "Por favor, selecione um arquivo para importação.";
          return;
        }
  
        this.loading = true;
        const formData = new FormData();
        formData.append('file', this.file);
  
        try {
          await this.$store.dispatch('senders/importSenders', formData);
          this.loading = false;
          this.closeModal(); 
          this.$emit('senders-imported'); 
        } catch (err) {
          this.loading = false;
          this.error = "Erro ao importar Remetentes. Tente novamente.";
          console.error(err);
        }
      },
      closeModal() {
        this.$emit('close');
      },
    },
  };
  </script>
  
  <style scoped>
  .modal-content {
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    background-color: #f9f9f9;
  }
  
  .modal-header {
    border-bottom: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .modal-title {
    font-size: 1.5rem;
    color: #4a4a4a;
  }
  
  .instruction-text {
    margin-bottom: 15px;
    font-size: 0.9rem;
    color: #6c757d;
  }
  
  .file-input {
    display: block;
    margin: 1rem 0;
    padding: 0.5rem;
    border: 1px solid #ced4da;
    border-radius: 5px;
    width: 100%;
    transition: border-color 0.3s;
  }
  
  .file-input:focus {
    border-color: #007bff;
    outline: none;
  }
  
  .button-group {
    display: flex;
    justify-content: space-between; 
    margin-top: 20px; 
  }
  
  .text-danger {
    color: #dc3545;
    margin-top: 10px;
  }
  
  .error-message {
    font-size: 0.85rem;
  }
  
  /* Responsividade */
  @media (max-width: 576px) {
    .modal-content {
      padding: 15px;
    }
    
    .modal-title {
      font-size: 1.25rem;
    }
    
    .instruction-text {
      font-size: 0.85rem;
    }
    
    .file-input, .btn {
      padding: 0.4rem;
      font-size: 0.85rem;
    }
  }
  </style>
  