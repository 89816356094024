<template>
    <tr @click="startEditing">
        <td>{{ fullname }}</td>
        <td>{{ street }}</td>
        <td>{{ neighborhood }}</td>
        <td>{{ number }}</td>
        <td>{{ city }}</td>
        <td>{{ uf }}</td>
        <td>{{ zipcode }}</td>
        <td>{{ created_at }}</td>
    </tr>
</template>

<script>
import moment from 'moment';

export default {
    props: ['sender'],
    data: (instance) => ({
        fullname: instance.sender.fullname,
        street: instance.sender.street,
        neighborhood: instance.sender.neighborhood,
        number: instance.sender.number,
        city: instance.sender.city,
        uf: instance.sender.uf,
        zipcode: instance.sender.zipcode,
        created_at: moment(instance.sender.created_at).format("DD/MM/YYYY HH:mm")
    }),
    methods: {
        startEditing() {
            this.$store.dispatch('senders/startEditing', this.sender)
        }
    }
}
</script>
