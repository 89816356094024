import api from "./axios";

export default {
    // Método para obter remetentes paginados
    async getRecipients(page = 1) {
        const response = await api.get(`/recipients?page=${page}`);
        return response.data;
    },
    
    // Método para obter todos os remetentes
    async getAllRecipients() {
        const response = await api.get(`/recipients?all=true`);
        return response.data;
    },

    // Método para adicionar um novo destinatário
    async addRecipient(recipient) {
        const response = await api.post('/recipients', recipient);
        return response.data;
    },

    // Método para atualizar um destinatário existente
    async updateRecipient(recipient) {
        const response = await api.put(`/recipients/${recipient.id}`, recipient);
        return response.data;
    },

    // Método para importar destinatários a partir de um arquivo
    async importRecipients(fileData) {
        const response = await api.post('/import/recipients', fileData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response.data;
    },
}
